import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components';
import { device } from '../utils';
import Img from "gatsby-image"

import Layout from '../components/layout';
import { PageTitle } from '../components/heading';
import { DripButton } from '../components/buttons';
import logo from '../images/Logo Round Shadow.svg';
import cookImg from '../images/Cook.svg';
import organiseImg from '../images/Organise.svg';
import shareImg from '../images/Share.svg';
import spoonImg from '../images/spoon.png';

const LandingPage = styled.div `
  > div:nth-child(odd) {
    background: var(--red);
    color: #FFF;
    position: relative;
    .words p {
      color: #FFF;
    }
  }
  @media ${device.mobileS} {}
  @media ${device.tablet} {}
  @media ${device.laptop} {
  }
`;

const AboveTheFold = styled.div`
  background: var(--red);
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: grid;

  .image-container {
    width: 100%;
    padding-top: 10px;
    img {
      width: 50%;
      max-width: 50%;
      height: auto;
      max-height: 100vh;
    }
  }
  .words-container {
    /* padding-bottom: 20px; */
    > p {
      font-family: Roboto;
      font-weight: 300;
      color: var(--white);
      text-align: center;
    }
  }

  @media ${device.mobileS} {
    grid-template-rows: auto 1fr;
    grid-template-rows: 1fr auto;
    /* padding-top: 130px; */
    .image-container {
      text-align: center;
      padding-top: 150px;
      img {
        max-width: 50vw;
        max-width: 50%;
        max-width: 100%;
        width: 100%;
      }
    }
    .words-container {
      display: block;
      flex-direction: column;
      margin-top: 15px;
      padding-bottom: 40px;
      > p {
        flex: 1;
        font-size: 3em;
        margin: 0;
      }
    }
  }
  @media ${device.mobileM} {
    .image-container {
      /* padding-top: 130px; */

    }
  }
  @media ${device.mobileL} {
    .image-container {
      padding-top: 130px;

    }
    .words-container {
      /* padding-bottom: 40px; */
    }
  }
  @media ${device.tablet} {
    .image-container {
      position: absolute;
      right: 0;
      top: 40px;
      margin-right: 30px;
      margin-top: 10px;
      text-align: right;
      padding-top: 30px;
      img {
        width: 50% ;
      }
    }
    .words-container {
      position: absolute;
      width: 60%;
      height: auto;
      /* max-height: 45%; */
      left: 0;
      bottom: 0;
      margin-left: 30px;
      margin-bottom: 30px;
      display: flex;
      padding-bottom: 40px;
      padding-top: 30px;
    
      > p {
        /* font-size: 3em; */
        margin: 0;
        margin-top: 30px;
        &:first-child { text-align: left; }
        &:last-child {
          text-align: right;
          margin-right: 110px; 
        }
      }
    }
  }

  @media ${device.laptop} {
    .words-container {
      width: 60%;
      > p {
        font-size: 5em;
      }
    }
  }
`;

const InfoSection = styled.div `
  height: auto;
  min-height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    padding-top: 20px;
  }
  .upper {
    display: flex;
    flex: 1;
    align-items: center;
    .text {
      ul {
        list-style: none;
        li {
          margin: 1.5rem 0;
          position: relative;
          text-align: left;
          line-height: 1.4em;

          &:before {
            content: " ";
            background-size: cover;
            background-image: url(${spoonImg});
            width: 3.8rem;
            height: 1.7rem;
            position: absolute;
            left: -4.3rem;
            transform: rotate(0deg);

            background-repeat:   no-repeat;
            background-position: center center;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
  .words {
    justify-items: flex-end;
    display: flex;
    > p {
      flex: 1;
      color: var(--grey);
      font-weight: 300;
      text-transform: uppercase;
      &:nth-child(2) {
        text-align: center;
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }

  @media ${device.mobileS} {
    .upper {
      margin: 20px;
      flex-direction: column;
      .image {
        img { max-width: 100%; }
      }
      .text p {
        line-height: 1.8em;
        text-align: center;
      }
    }
    .words {
      margin: 10px 13px;
      > p {
        font-size: 16px;
      }
    }
  }
  @media ${device.tablet} {
    > div {
      padding: 0 10%;
    }
    
    .upper {
      .text {
        padding-left: 50px;
        p {
          line-height: 2em;
        }
      }
      .image {
        img { max-width: 350px; }
      }
    }
    .words {
    }
    @media ${device.laptop} {
      .upper {
        flex-direction: row;
        .text {
          max-width: 800px;
          margin: 0 auto;
        }
      }
    }
  }
`;

const IndexPage = ({data}) => (
  <Layout 
    title={'My Apron'}
    metaDescription={``}
    isHome={true}
  >
<LandingPage>

<AboveTheFold>

  <div className={'image-container'}>
    <img src={logo} alt="logo" />
  </div>
  <div className={'words-container'}>
    <p>Organise</p>
    <p>Share</p>
    <p>Cook</p>
  </div>

</AboveTheFold>

<InfoSection>
  {/* <div className="words">
    <p>Upload</p>
    <p>Categorise</p>
    <p>Note-take</p>
  </div> */}
  <div><h1>Welcome to My Apron</h1></div>
  <div className="upper">
    <div className="image">
    <img src={organiseImg} alt="Laptop with cookbooks" />
    </div>
    <div className="text">
      <p>
        <span>Manage your collection of recipes by adding, categorising and searching with My Apron.</span>
        <ul>
          <li>Upload a photo of a recipe to quickly find it next time.</li>
          <li>Search for a last-minute dessert to make, and easily find the perfect one in your collection.</li>
        </ul>
      </p>
      <p>With My Apron you will be back cooking in the kitchen before you can say <i>"I’m Hungry!"</i></p>
    </div>
  </div>
</InfoSection>
<InfoSection>
  {/* <div className="words">
    <p>Private</p>
    <p>Friends</p>
    <p>Public</p>
  </div> */}
  <div className="upper">
    <div className="image">
    <img src={shareImg} alt="Hand holding recipe" />
    </div>
    <div className="text">
      <p>Experiences and memories with food are a cornerstone in everyone’s life, bringing happiness and unity to family and friends.</p>
      <p>
        <span>My Apron makes it a piece of cake to share your recipes!</span>
        <ul>
          <li>Send to your friend the recipe of the delicious cookies you baked.</li>
          <li>Collate a cookbook to pass down the generations, so your family recipes can live on</li>
          <li>Or keep all your recipes private.</li>
        </ul>
      </p>
      <p>Whichever you decide to do, your recipes will always be there, readily available for you.</p>
    </div>
  </div>
</InfoSection>
<InfoSection>
  {/* <div className="words">
    <p>Search</p>
    <p>Plan</p>
    <p>Cook</p>
  </div> */}
  <div className="upper">
    <div className="image">
    <img src={cookImg} alt="Wooden spoon" />
    </div>
    <div className="text">
      <p>Join up to the My Apron Family and enjoy the time-efficient management of your recipes.</p>
      <p>My Apron has you covered in the kitchen!</p>
      <p>Let’s start cooking!</p>
    </div>
  </div>
</InfoSection>

</LandingPage>
</Layout>
)

export default IndexPage
